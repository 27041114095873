import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  reduxForm,
  Field,
  FieldArray,
  formValueSelector,
  getFormValues,
} from "redux-form";
import _isEmpty from 'lodash/isEmpty';
import { AvatarGroup } from "@material-ui/lab";
import Swal from "sweetalert2";
import { Avatar } from "@material-ui/core";
import { Spin } from 'antd';

import ThemeButton from "../../../../../partials/button/ThemeButton";
import Item from "../../../../../partials/display/Item";
import TextInput from "../../../../../partials/form/TextInput";
import { connect, useSelector } from "react-redux";
import ThemeHeader from "../../../../../partials/content/ThemeHeader";
import { AddStreetsTable } from "./AddStreetsTable";
import { AddSignTable } from "./AddSignTable";
import { ZonesContext } from "../../../pages/zoneAnalysis/ZoneAnalysisContextProvider";
import { getAllMembers } from "../../../../../GeneralApi/GeneralApi";
import MultiSelect from "../../../../../partials/form/MultiSelect";
import MemberName from "../../../../../partials/popover/memberPopover/MemberName";
import {
  postZoneAnalysis,
  updateZoneAnalysis,
  deleteZone,
} from "../../../curd/prospectsCrud";
import { AddZoneAnalysisContext } from "./AddZoneAnalysisContextProvider";
import AccessControl from "../../../../../_ accessControl/AccessControl";
import { toast } from "react-toastify";
import DateAndTime from "../../../../../partials/form-basic/dateAndTime/DateAndTime";
const validate = values => {
  const errors = {};
  const { contacts, name } = values
  if (!name) {
    errors.name = 'Required'
  }
  if (!contacts?.length) {
    errors.contacts = 'Required'
  }
  return errors;
};

const AddNewZoneAnalysisForm = (props) => {
  const {
    handleSubmit,
    profileId,
    type,
    formValue,
    closeModal,
    detailsData,
    invalid,
    pristine,
    streets,
    submitting,
    change,
    formName,
    initialValues
  } = props;
  const {
    addZoneSuburb,
    addZoneStreet,
    addZoneSaleState,
    update,
    zoneAnalysis_of,
    ZONE_TYPE
  } = useContext(ZonesContext);
  console.log('__initialValues', initialValues)
  const { zoneId, streetZoneId, isLoadingContext, getZoneAnalysisStreets,  } = useContext(AddZoneAnalysisContext);
  const user = useSelector((state) => state.auth.user.contact);

  const [selectedStreets, setSelectedStreets] = useState(null);
  const [selectedTempStreets, setSelectedTempStreets] = useState(null);
  const [signsAdded, setSignsAdded] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState(initialValues.contacts ?? [])
  const [accessCondition, setAccessCondition] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [contactHistoryFrom, setContactHistoryFrom] = useState(new Date());
  const [members, setMembers] = useState(null);
  useEffect(() => {
    getAllMembers()
      .then(({ data: { data } }) => {
        setMembers(
          data.map((s) => ({
            name: `${s.initial}`,
            id: s.contact.id,
            optionTooltipTitle: `${s?.contact?.first_name ?? ''} ${s?.contact?.last_name ?? ''}`,
          }))
        );
      })
      .catch((e) => { });

  }, []);
  useEffect(() => {
    if (initialValues?.id) {
      getZoneAnalysisStreets({ zone: initialValues?.id });
    }
  }, [initialValues?.id])
  const handleStreetsAdd = () => {
    console.log(props.streets);
    // getZoneAnalysisEdit(props.streets)
    //   .then(({ data }) => {
    //     console.log(data);
    //     setItems(data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };

  const handleOnSubmit = async (values) => {
    const { streets, signs, id, contacts, buyers_count, contacted, listings_count_others, listings_count_wb, properties_count, sold_properties_count_others, sold_properties_count_wb, streets_count, ...others } = values;
    console.log(values);
    let processedValue = {
      id: id,
      contacts: formValue.contacts.filter(id => id !== 'all'),
      name: formValue?.name,
      type: ZONE_TYPE,
      zone: formValue?.id,
      ...others
    }


    if (selectedTempStreets.streets?.length) {
      processedValue.streets = [{
        suburb: selectedTempStreets.suburb,
        add_streets: selectedTempStreets.streets.map(data => data.id),
        remove_streets: []
      }]
    }

    if (id || streetZoneId) {
      processedValue._method = "PATCH";
      setIsLoading(true);
      let zone_id = processedValue.id ? processedValue.id : streetZoneId;
      await updateZoneAnalysis(
        zone_id, processedValue
      )
        .then((res) => {
          closeModal();
          toast.success(res?.data?.message)
        })
        .catch((e) => {
          toast.error(e?.data?.message)
        })
        .finally(() => {
          setIsLoading(false);
        })
    } else {
      setIsLoading(true);
      await postZoneAnalysis(processedValue)
        .then((res) => {
          toast.success(res?.data?.message)
          closeModal();
        })
        .catch((e) => { toast.error(e?.data?.message) })
        .finally(() => {
          setIsLoading(false);
        })
    }
    update();
  };

  const handleDeleteZone = (id) => {
    Swal.fire({
      title: "Removing Zone?",
      text: "This will remove " + formValue?.name + "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#A7A9AC",
      cancelButtonColor: "#2BB673",
      confirmButtonText: "Cancel",
      cancelButtonText: "Yes",
    }).then((result) => {
      if (result.isDismissed) {
        deleteZone(formValue.id)
          .then((data) => {
            update();
            closeModal();
          })
          .catch((e) => { });
      }
    });
  };

  useEffect(() => {
    if (_isEmpty(initialValues)) {
      setAccessCondition(ZONE_TYPE === 1 ? ["zone_analysis_sales-add"] : ["zone_analysis_rentals-add"])
    } else {
      setAccessCondition(ZONE_TYPE === 1 ? ["zone_analysis_sales-edit"] : ["zone_analysis_rentals-edit"])
    }
  }, [ZONE_TYPE, initialValues])

  useEffect(() => {
    change('contacts', selectedTeams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeams])

  const handleDateChange = (value) => {
    setContactHistoryFrom(value)
    getZoneAnalysisStreets({ zone: initialValues?.id  , contact_history_from: value })
  };

  const handleTeamChange = (curr, _, prev) => {
    let teams = curr
    if (curr.length < prev.length) { // Deselect
      if (prev.includes('all') && !curr.includes('all')) { // Deselect all
        teams = []
      } else { //Deselect the single
        teams = curr.filter(item => item !== 'all')
      }
    } else if (curr.includes('all')) { // Select all
      teams = ['all', ...members.map(item => item.id)]
    }
    setSelectedTeams(teams)
  }
  return (
    <Spin spinning={submitting || isLoadingContext} tip="Loading...">
      {/* {isLoadingContext ? <LinearProgress /> : ''} */}
      <Container className="my-profile" fluid>
        <form
          className="contactForm"
          onSubmit={handleSubmit((e) => handleOnSubmit(e))}
        >
          {" "}
          <Row>
            <Col lg={4}>
              <Row style={{ paddingTop: "0.8rem" }}>
                {" "}
                <Col lg={6}>
                  <h5 className="title" style={{ paddingLeft: "1rem" }}>
                    {_isEmpty(initialValues) ?
                      'Add Zone' :
                      <AccessControl
                        allowedPermissions={accessCondition}
                        renderNoAccess={() => <div className='d-flex align-items-center'>
                          <div>{initialValues.name}</div>
                          <div className='testimonial-avatar'>
                            <AvatarGroup max={15}>
                              {initialValues?.contacts?.length
                                ? initialValues?.contacts.map((obj) => (
                                  <Avatar alt={obj?.initial}>
                                    <MemberName id={obj?.id} className='pr-0'>
                                      {obj?.initial ?? '??'}
                                    </MemberName>
                                  </Avatar>
                                ))
                                : ""}
                            </AvatarGroup>
                          </div>
                        </div>}
                      >
                        Edit Zone
                      </AccessControl>}
                  </h5>
                </Col>
                <Col
                  lg={5}
                  className="float-right"
                  style={{ paddingTop: "0.5rem" }}
                >
                  {" "}

                  <AccessControl
                    allowedPermissions={accessCondition}
                    renderNoAccess={() => ""}
                  >
                    <div>
                      <Item
                        left={"Name"}
                        right={
                          <Field
                            name="name"
                            type="text"
                            component={TextInput}
                            showErrorOnMount={true}
                            placeholder="Zone Name"
                          />
                        }
                      />
                    </div>
                  </AccessControl>
                </Col>
              </Row>
            </Col>
            <Col lg={8}>
              {" "}
              <AccessControl
                allowedPermissions={accessCondition}
                renderNoAccess={() => <ThemeButton
                  className="lightGrey-button float-right"
                  bold
                  onClick={() => closeModal()}
                >
                  Return
                </ThemeButton>
                }
              >
                <ThemeHeader
                  left={
                    <div className="d-flex">
                      <div className="mt-3 align-self-center"> Team:</div>
                      <Field
                        style={{ width: '180px' }}
                        name="contacts"
                        type="text"
                        options={members}
                        component={MultiSelect}
                        showErrorOnMount={true}
                        restrictMaxTagCount={true}
                        isMulti={true}
                        lable="Team"
                        showDelete={true}
                        size='small'
                        className="pl-2"
                        otherValue={[{ name: "All", id: "all" }]}
                        onChange={handleTeamChange}
                      />

                      {formValue?.id ? (
                        <div className="pl-3 mt-auto">
                          <ThemeButton
                            onClick={() => handleDeleteZone()}
                            className="brand-button"
                          >
                            Delete Zone
                          </ThemeButton>
                        </div>
                      ) : (
                        ""
                      )}

                      {formValue?.id ? (
                          <>
                            <div className="mt-3 ml-3 align-self-center">Contacted On:</div>
                            <DateAndTime
                                label="Month"
                                name="contact_history_from"
                                value={contactHistoryFrom}
                                type="month"
                                className="ml-2"
                                onChange={(e) => handleDateChange(e.target.value)}
                                fixedHeight
                            />
                          </>
                      ) : (
                          ""
                      )}

                    </div>
                  }
                  right={
                    <Fragment>
                      <div className="pl-2">
                        <ThemeButton
                          className="lightGrey-button"
                          bold
                          onClick={() => closeModal()}
                        >
                          Cancel
                        </ThemeButton>
                      </div>
                      <div className="pl-2">
                        <ThemeButton
                          bold
                          type="submit"
                          disabled={invalid || pristine || submitting}
                          className="submit-button"
                        >
                          Save
                        </ThemeButton>
                      </div>
                    </Fragment>
                  }
                />
              </AccessControl>
            </Col>
          </Row>
          <div className="row">
            <Col lg={4} className="pb-md-3">
              <Container fluid>
                <FieldArray
                  name="streets"
                  change={change}
                  handleStreetsAdd={handleStreetsAdd}
                  formValue={formValue}
                  handleChange={(e) => setSelectedStreets(e)}
                  setSelectedTempStreets={setSelectedTempStreets}
                  // setStreetsAdded={setStreetsAdded}
                  // streetsAdded={streetsAdded}
                  component={AddStreetsTable}
                  suburbOptions={addZoneSuburb}
                  streetOptions={addZoneStreet}
                  initialValues={initialValues}
                  disableStreetsuburb={formValue?.contacts?.length && formValue?.name}
                />
              </Container>
            </Col>
            <Col lg={8}>
              <Container fluid>
                <AddSignTable name="signs"
                  change={change}
                  formValue={formValue}
                  handleStreetsAdd={handleStreetsAdd}
                  handleChange={(e) => setSelectedStreets(e)}
                  setSignsAdded={setSignsAdded}
                  signsAdded={signsAdded}
                  component={AddSignTable}
                  streetOptions={streets?.streets}
                  saleOptions={addZoneSaleState}
                  initialValues={initialValues} />
              </Container>
            </Col>
          </div>
        </form>
      </Container>
    </Spin>
  );
};

const selector = formValueSelector("AddZoneAnalysis");

const mapState = (state, ownProps) => {
  const streets = selector(state, "streets", "suburb", "streetList");
  const values = getFormValues("AddZoneAnalysis")(state);
  return {
    formValue: values,
    streets: streets,
  }; // Form name must have to be different
};

export default connect(
  mapState,
  null
)(
  reduxForm({ form: "AddZoneAnalysis", validate, enableReinitialize: true })(
    AddNewZoneAnalysisForm
  )
);
