import React, { useState, createContext, useEffect, useContext } from "react";
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import {
  postZoneAnalysis,
  updateZoneAnalysis,
  updateSign,
  postSign,
  getZoneAnalysisById,
  getAllProspects,
  createProspect,
  getZoneAnalysisSalesStreets,
  deleteZoneAnalysisSalesStreets,
  updateZoneAnalysisSalesStreets,
} from "../../../curd/prospectsCrud";
import {
  convertFormData,
  errorAlert,
  formToQueryString,
  getProcessedZoneAnalysis,
} from "../../../../../_helpers/_utils/utils";
import { ZonesContext } from "../../../pages/zoneAnalysis/ZoneAnalysisContextProvider";
import { toast } from "react-toastify";

export const AddZoneAnalysisContext = createContext();
export const { Consumer } = AddZoneAnalysisContext;

const AddZoneAnalysisContextProvider = (props) => {
  const [initialValues, setInitialValues] = useState({});
  const [signTableData, setSignTableData] = useState([]);
  const [filterCompanies, setFilterCompanies] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [filterSigns, setFilterSigns] = useState("all");
  const [zoneId, setZoneId] = useState(null);
  const [addStreetLoading, setAddStreetLoading] = useState(false);
  const [addSignLoading, setAddSignLoading] = useState(false);
  const { update, ZONE_TYPE } = useContext(ZonesContext);
  const [otherSignResData, setOtherSignResData] = useState([]);
  const [zoneStreets, setZoneStreets] = useState([]);
  const [otherZoneData, setOtherZoneData] = useState([]);
  const [streetZoneId, setStreetZoneId] = useState(null);
  const [prevStreetData, setPrevStreetData] = useState([]);
  const [contactHistoryFrom, setContactHistoryFrom] = useState(null);

  useEffect(() => {
    if (props?.zoneId) {
      getZoneData(props?.zoneId);
    }
  }, [props.zoneId]);

  const updateSignTable = async (values) => {

    let obj = {
      limit: 50,
      ...values
    };
    if (filterCompanies == "all") {
      if (ZONE_TYPE === 1 && filterCompanies) {
        obj.lwu = [0, 1]
      }
      else {
        obj.rwu = [0, 1]
      }
    }
    else {
      if (ZONE_TYPE === 1) {
        obj.lwu = filterCompanies
      }
      else {
        obj.rwu = filterCompanies
      }
    }

    if (filterSigns != "all") {
      obj.status = filterSigns;
    }


    let qString = formToQueryString(obj);
    setAddSignLoading(true)
    await getAllProspects(qString)
      .then((res) => {
        const { data, ...rest } = res.data;
        setIsLoading(false);
        if (values?.loadMore) {
          setSignTableData(signTableData.concat(data));
        } else {
          setSignTableData(data);
        }
        setOtherSignResData(rest);
      })
      .catch(err => {
        errorAlert(err?.data)
      })
      .finally(() => {
        setAddSignLoading(false)
      })

  };

  const addNewStreet = (values, formValue) => {
    return new Promise((resolve, reject) => {
      setAddStreetLoading(true)
      let obj = { ...values }
      let zone_Id;
      let tempData = [];
      let newData = values?.streets[0];
      if (!streetZoneId && !obj.zone) {
        delete (obj.zone)
        postZoneAnalysis(values)
          .then(({ data: { data } }) => {
            zone_Id = data.id
            setStreetZoneId(data?.id);
            let params = {
              zone: zone_Id
            }
            getZoneAnalysisStreets(params);
            update();
            toast.success(data?.message);
          })
          .catch((e) => { })
          .finally(() => {
            setAddStreetLoading(false)
            resolve();
          })
      } else {
        let dataValues = {}
        let zoneId = obj?.zone ? obj.zone : streetZoneId;
        dataValues = {
          ...values,
          _method: "PATCH",
        };
        updateZoneAnalysisSalesStreets(zoneId, dataValues).then(res => {
          getZoneAnalysisStreets({ zone: zoneId })
          update();
        }).catch((err) => {

        }).finally(() => {
          setAddStreetLoading(false)
          resolve();
        })
      }
    });
  }

  const getZoneAnalysisStreets = (values) => {
    setAddStreetLoading(true)
    let obj = {
      ...values, limit: 20
    }
    if (obj.loadMore) {
      delete (obj.loadMore)
    }
    //add contactFromhistory if available
    if (obj.contact_history_from) {
      setContactHistoryFrom(obj.contact_history_from)
    }
    let queryString = formToQueryString(obj)
    getZoneAnalysisSalesStreets(queryString).then(res => {
      if (values.loadMore) {
        setZoneStreets(zoneStreets.concat(res?.data?.data))
      } else {
        setZoneStreets(res?.data?.data)
      }
      setOtherZoneData(res?.data)
    }).catch(err => {

    }).finally(() => {
      setAddStreetLoading(false)
    });
  }
  const deleteZoneAnalysisStreets = (processedData) => {
    return new Promise((resolve, reject) => {
      deleteZoneAnalysisSalesStreets(processedData).then(res => {
        toast.success(res?.data?.data?.message);
        setPrevStreetData([]);
        getZoneAnalysisStreets({ zone: processedData?.zone });
        update();

      }).catch((err) => {

      }).finally(() => {
        resolve();
      })
    })
  }
  const handleAddNewStreet = async (values, formValue) => {
    const { streets, buyers_count, contacted, listings_count_others, listings_count_wb, properties_count, sold_properties_count_others, sold_properties_count_wb, streets_count, ...others } = formValue;

    let processedValue = {
      streets: values.map((obj) => obj?.id),
      id: initialValues?.id,
      ...others,
    };

    let newZone;
    if (initialValues?.id || zoneId) {
      processedValue._method = "PATCH";
      setAddStreetLoading(true)
      await updateZoneAnalysis(
        processedValue.id ? processedValue.id : zoneId, processedValue
      )
        .then(({ data: { data } }) => {
          newZone = data.id;
        })
        .catch((e) => { })
        .finally(() => {
          setAddStreetLoading(false)
        })
    } else {
      setAddStreetLoading(true)
      await postZoneAnalysis(processedValue)
        .then(({ data: { data } }) => {
          newZone = data.id;
          setZoneId(data.id);
        })
        .catch((e) => { })
        .finally(() => {
          setAddStreetLoading(false)
        })
    }

    await updateSignTable({ zone: initialValues?.id });
    await update();
  };

  const handleAddNewSign = async (values, formValue) => {
    let processedValue = {
      ...values
    };
    if (ZONE_TYPE == 1) {
      processedValue.listing = 1
    }
    else {
      processedValue.rental = 1
    }

    if (values?.lwo?.toUpperCase()?.includes('WISEBERRY')) {

      if (ZONE_TYPE == 1) {
        processedValue.lwu = 1
      }
      else {
        processedValue.rwu = 1
      }
    }
    else {
      if (ZONE_TYPE == 1) {
        processedValue.lwu = 0
      }
      else {
        processedValue.rwu = 0
      }
    }
    setAddSignLoading(true)
    await createProspect(processedValue)
      .then(({ data: { data } }) => { })
      .catch((e) => { })
      .finally(() => {
        setAddSignLoading(false)
      })
    updateSignTable({ zone: formValue?.id });
    update();
  };

  const updateSignInfo = async (values, formValue) => {

    let processedValue = {
      ...values,
    };



  };

  const getZoneData = async (id) => {
    setIsLoading(true);
    await getZoneAnalysisById(id)
      .then(({ data: { data } }) => {

        setInitialValues(getProcessedZoneAnalysis(data));
        setIsLoading(false);
      })
      .catch((e) => { });
  };


  const fetchMoreSign = async () => {
    if (otherSignResData?.next_page_url) {
      updateSignTable({ page: otherSignResData.current_page + 1, loadMore: true });
    }
  };
  const fetchMoreStreets = async (zone_ID) => {
    if (otherZoneData?.next_page_url) {
      if(contactHistoryFrom){
        getZoneAnalysisStreets({ page: otherZoneData.current_page + 1, loadMore: true, zone: zone_ID, contact_history_from: contactHistoryFrom });
      }
      else{
        getZoneAnalysisStreets({ page: otherZoneData.current_page + 1, loadMore: true, zone: zone_ID });
      }
    }
  };

  return (
    <AddZoneAnalysisContext.Provider
      value={{
        initialValues,
        fetchMoreSign,
        handleAddNewStreet,
        handleAddNewSign,
        updateSignTable,
        updateSign: updateSignInfo,
        signTableData,
        filterCompanies,
        setFilterCompanies,
        filterSigns,
        setFilterSigns,
        zoneId,
        ZONE_TYPE,
        isLoadingContext: isLoading,
        addNewStreet,
        // updateTalbe = {},
        addStreetLoading,
        addSignLoading, otherZoneData, zoneStreets, streetZoneId, deleteZoneAnalysisStreets, getZoneAnalysisStreets,
        fetchMoreStreets, setPrevStreetData, prevStreetData
      }}
    >
      {props.children}
    </AddZoneAnalysisContext.Provider>
  );
};

export default AddZoneAnalysisContextProvider;
